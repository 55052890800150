// @ts-nocheck
import {
  center,
  bearing,
  distance,
  transformTranslate,
  lineToPolygon,
  rhumbBearing,
  rhumbDistance,
  unkinkPolygon,
  area,
  cleanCoords,
  polygon,
  point,
  featureCollection,
  lineString,
} from '@turf/turf'
import { formatMoney } from 'accounting'
import { isArray, isObject, merge, unset } from 'lodash'
import * as Sentry from "@sentry/browser"

import { DEFAULT_CAPABILITIES, SUBSCRIPTION_CAPABILITIES, uuidRegex } from '../constants/index'
import { getText } from './i18n'
import { axios } from '../api/axios'

export function secondsToTime(e: number, showHours = false): string {
  const m = Math.floor(e % 3600 / 60).toString().padStart(2, '0')
  const s = Math.floor(e % 60).toString().padStart(2, '0')

  let time = `${m}:${s}`
  if (showHours) {
    const h = Math.floor(e / 3600).toString().padStart(2, '0')
    time = `${h}:` + time
  }
  return time
}

export function isValidBounds(bounds: GeoJSON.BBox): boolean {
  return (
    bounds &&
    bounds.length === 4 &&
    !bounds.includes(Infinity) &&
    !bounds.includes(-Infinity)
  )
}

export function getMediaHref(obj: IMedium[], prop: TMediaRel = 'cover-photo'): string {
  if (obj && obj.length > 0) {
    const cover = obj.find((media) => media.rel === prop)
    return cover ? cover.href : ''
  }
  return ''
}

export function getMediaSize(obj: IMedium[], width = 300, prop: TMediaRel = 'cover-photo'): string {
  if (obj && obj.length > 0) {
    const cover = obj.find((m) => m.rel === prop)
    if (cover && cover.sizes) {
      let size = cover.sizes.find(s => s.width === width)
      if (!size) {
        size = cover.sizes.find(s => s.width >= width)
      }
      if (size && size.href) {
        return size.href
      }
      return cover ? cover.href : ''
    }
  }
  return ''
}

export function getMediaHrefIndex(obj: IMedium[], prop: TMediaRel = 'cover-photo'): number {
  if (obj && obj.length > 0) {
    return obj.findIndex((media) => media.rel === prop)
  }
  return -1
}

export function setMediaHref(
  href: string,
  obj: IMedium[],
  prop: TMediaRel = 'cover-photo'
): IMedium[] {
  if (obj) {
    const i = obj.findIndex((o) => o.rel === prop)
    if (i === -1) {
      // no match found, we push instead
      // @TODO: must change the type to the correct MIME type
      obj.push({ href, rel: prop, type: 'image/jpg' })
    } else {
      obj[i].href = href
    }
  }
  return obj
}

export function removeCoverImage(prop: IMedium[]): IMedium[] {
  if (prop && prop.length > 0) {
    const i = prop.findIndex((o) => o.rel === 'cover-photo')
    if (i !== -1) {
      // only splice if we find a matching element
      prop.splice(i, 1)
    }
  }
  return prop
}

export function unkinkPoly(poly?: GeoJSON.Polygon): void {
  if (poly) {
    let ls
    let polyJson
    try {
      ls = lineString(poly.coordinates[0])
    } catch (err) {
      Sentry.captureException(err)
      console.error('Error creating linestring')
      console.error(err)
      console.error(poly)
    }
    if (ls) {
      try {
        polyJson = lineToPolygon(ls)
      } catch (err) {
        Sentry.captureException(err)
        console.error('Error creating polygon')
        console.error(err)
        console.error(ls)
      }
      if (polyJson) {
        let cleanedCoords
        try {
          // try to remove coord duplicates before
          cleanedCoords = cleanCoords(polyJson)
        } catch (err) {
          Sentry.captureException(err)
          console.error('Error cleaning polygon')
          console.error(err)
          console.error(polyJson)
        }
        if (cleanedCoords) {
          let unkinkedPolygons
          try {
            // make sure there aren't any kinks
            unkinkedPolygons = unkinkPolygon(cleanedCoords)
          } catch (err) {
            Sentry.captureException(err)
            console.error('Error unkinking polygon')
            console.error(err)
            console.error(cleanedCoords)
          }
          if (unkinkedPolygons) {
            let largestPoly
            let largestArea = 0
            // find the largest area and keep that
            for (const unkinkedPolygon of unkinkedPolygons.features) {
              try {
                const uKPA = area(unkinkedPolygon)
                if (uKPA > largestArea) {
                  largestArea = uKPA
                  largestPoly = unkinkedPolygon
                }
              } catch (err) {
                Sentry.captureException(err)
                console.error('Error getting area of polygon')
                console.error(err)
                console.error(unkinkedPolygon)
              }
            }
            if (largestPoly) {
              // keep the largest polygon
              poly.coordinates = largestPoly.geometry.coordinates as [number, number][][]
            }
          }
        }
      }
    }
  }
}

export function findElementFromCollection(
  collection: ICollectionModel,
  element: IElementModel
): IElementModel | undefined | null {
  const echo = collection.echoes.find(
    (echo) => echo.elements.indexOf(element) !== -1
  )
  let i = -1
  if (echo) {
    i = echo.elements.indexOf(element)
  }
  return i !== -1 ? echo?.elements[i] : null
}

export function movePolygonToCentreOfEcho(poly: GeoJSON.Polygon, circle: GeoJSON.Point): GeoJSON.Polygon | null {
  let polyJson
  try {
    polyJson = polygon(poly.coordinates)
  } catch (err) {
    Sentry.captureException(err)
    console.error('Error converting to polygon')
    console.error(err)
    console.error(poly)
  }
  if (!polyJson) {
    const coords = poly.coordinates[0]
    try {
      const line = lineString(coords)
      polyJson = lineToPolygon(line)
    } catch (err) {
      Sentry.captureException(err)
      console.error('Error converting coords to line to poly')
      console.error(err)
      console.error(coords)
    }
  }
  if (polyJson) {
    // get the centre of the poly
    const polyCentre = center(polyJson)
    // get the bearing from the poly to the circle
    const polyBearing = (bearing(polyCentre, circle.coordinates) + 360.0) % 360.0
    const polyDistance = distance(polyCentre, circle.coordinates)
    transformTranslate(polyJson, polyDistance, polyBearing, {
      mutate: true,
    })
    return polyJson.geometry as GeoJSON.Polygon
  } else {
    return null
  }
}

export function moveCircleToCentreOfPolygon(circle: GeoJSON.Point, poly: GeoJSON.Polygon): [number, number] {
  // get the centre of the poly
  const polyCentre = center(poly)
  // get the bearing from the circle to the poly
  const circleBearing = bearing(circle.coordinates, polyCentre)
  const circleDistance = distance(circle.coordinates, polyCentre)
  const newLoc = transformTranslate(circle, circleDistance, circleBearing, {
    mutate: true,
  })
  return newLoc.coordinates as [number, number]
}

export function moveCoordsToNewLocation(source: [number, number], distance: number, bearing: number): [number, number] | undefined {
  const p = point(source)
  const tP = transformTranslate(p, distance, bearing)
  if (tP.geometry) {
    return tP.geometry.coordinates as [number, number]
  }
  return undefined
}

export function moveCollectionToNewLocation(collection: ICollectionModel, destination: [number, number]): void {
  const f = getCollectionFeatures(collection)
  const fc = featureCollection(f)
  const c = center(fc)
  const d = rhumbDistance(c, destination)
  const b = rhumbBearing(c, destination)
  const fcTrans = transformTranslate(fc, d, b)
  setCollectionFeatures(collection, fcTrans.features)
}

export function moveEchoesToNewLocation(echoes: IEchoModel[], destination: [number, number]): void {
  let f: GeoJSON.Feature<GeoJSON.Geometry>[] = []
  for (const echo of echoes) {
    const echoFeatures = getEchoFeatures(echo)
    f = [...f, ...echoFeatures]
  }
  const fc = featureCollection(f)
  const c = center(fc)
  const d = rhumbDistance(c, destination)
  const b = rhumbBearing(c, destination)
  const fcTrans = transformTranslate(fc, d, b)
  setEchoesFeatures(echoes, fcTrans.features)
}

export function moveEchoToNewLocation(echo: IEchoModel, destination: [number, number]): void {
  const features = getEchoFeatures(echo)
  const fc = featureCollection(features)
  const c = center(fc)
  const d = rhumbDistance(c, destination)
  const b = rhumbBearing(c, destination)
  const fcTrans = transformTranslate(fc, d, b)
  setEchoFeatures(echo, fcTrans.features)
}

export function getCollectionFeatures(collection: ICollectionModel): GeoJSON.Feature<GeoJSON.Geometry>[] {
  let f: GeoJSON.Feature<GeoJSON.Geometry>[] = []
  if (collection.loc?.coordinates) {
    const pnt = point(collection.loc.coordinates, {
      feature: 'collection',
      property: 'loc.coordinates',
      _id: collection._id
    })
    if (pnt) {
      f.push(pnt)
    }
  }
  for (const echo of collection.echoes) {
    const ef = getEchoFeatures(echo)
    f = [...f, ...ef]
  }
  for (let i = 0; i < collection.trajectories.length; i++) {
    const trajectory = collection.trajectories[i]
    const tf = getCollectionTrajectoryFeatures(trajectory, i)
    f = [...f, ...tf]
  }
  return f
}

export function getEchoFeatures(echo: IEchoModel): GeoJSON.Feature<GeoJSON.Geometry>[] {
  let f: GeoJSON.Feature<GeoJSON.Geometry>[] = []
  if (echo.shape === 'Circle') {
    if (echo.loc?.coordinates) {
      const pnt = point(echo.loc?.coordinates, {
        feature: 'echo',
        property: 'loc.coordinates',
        _id: echo._id
      })
      if (pnt) {
        f.push(pnt)
      }
      if (echo.polygon && pnt) {
        let poly: GeoJSON.Feature<GeoJSON.Polygon> | null = null
        try {
          poly = polygon(echo.polygon?.coordinates, {
            feature: 'echo',
            property: 'polygon.coordinates',
            _id: echo._id
          })
        } catch (err) {
          console.error(err)
        }
        if (poly) {
          f.push(poly)
          const polyCentre = center(poly)
          const d = distance(polyCentre, pnt)
          const b = bearing(polyCentre, pnt)
          const polyTrans = transformTranslate(poly, d, b)
          // it's a circle, move the poly to the centre of the circle
          if (polyTrans) {
            f.push(polyTrans)
          }
        }
      }
    }
  } else {
    if (echo.polygon) {
      const poly = polygon(echo.polygon?.coordinates, {
        feature: 'echo',
        property: 'polygon.coordinates',
        _id: echo._id
      })
      if (poly) {
        f.push(poly)
        const polyCentre = center(poly, {
          properties: {
            feature: 'echo',
            property: 'loc.coordinates',
            _id: echo._id
          }
        })
        // it's a polygon, move the circle to the centre of the polygon
        if (polyCentre) {
          f.push(polyCentre)
        }
      }
    }
  }


  for (const element of echo.elements) {
    if (element.threed) {
      // only bother moving threed elements

      const elf = getElementFeatures(element)
      f = [...f, ...elf]
    }
  }
  return f
}

export function getElementFeatures(element: IElementModel): GeoJSON.Feature<GeoJSON.Geometry>[] {
  const f: GeoJSON.Feature<GeoJSON.Geometry>[] = []
  if (element.loc?.coordinates) {
    const pnt = point(element.loc?.coordinates, {
      feature: 'element',
      property: 'loc.coordinates',
      _id: element._id
    })
    if (pnt) {
      f.push(pnt)
    }
  }
  return f
}

export function getCollectionTrajectoryFeatures(trajectory: ITrajectoryModel, index: number): GeoJSON.Feature<GeoJSON.Geometry>[] {
  const f: GeoJSON.Feature<GeoJSON.Geometry>[] = []
  if (trajectory.trajectory?.coordinates) {
    const ls = lineString(trajectory.trajectory.coordinates, {
      feature: 'trajectory',
      property: 'trajectory.coordinates',
      _id: trajectory._id,
      index
    })
    if (ls) {
      f.push(ls)
    }
  }
  return f
}

export function setCollectionFeatures(collection: ICollectionModel, features: GeoJSON.Feature<GeoJSON.Geometry>[]): void {
  for (const f of features) {
    if (f.properties?.feature && f.properties?.property) {
      const featureType = f.properties.feature as string
      const featureProperty = f.properties.property as string
      const featureId = f.properties?._id as string | undefined
      switch (featureType) {
        case 'collection':
          if (featureProperty === 'loc.coordinates' && collection.loc?.coordinates && f.geometry.type === "Point") {
            collection.loc.coordinates = f.geometry.coordinates as [number, number]
          }
          break
        case 'echo':
          if (featureId) {
            const echo = collection.echoes.find(e => e._id === featureId)
            if (echo) {
              setEchoFeatures(echo, [f])
            }
          }
          break
        case 'element':
          if (featureId && featureProperty === 'loc.coordinates') {
            let element: IElementModel | undefined
            for (const echo of collection.echoes) {
              for (const el of echo.elements) {
                if (el._id === featureId) {
                  element = el
                }
              }
            }
            if (element && element.loc?.coordinates && f.geometry.type === "Point") {
              element.loc.coordinates = f.geometry.coordinates as [number, number]
            }
          }
          break
        case 'trajectory': {
          let index = -1
          if (f.properties.index) {
            index = f.properties.index as number
          }
          if (index > -1) {
            const trajectory = collection.trajectories[index]
            if (trajectory && trajectory.trajectory?.coordinates && f.geometry.type === "LineString") {
              trajectory.trajectory.coordinates = f.geometry.coordinates as [number, number][]
            }
          }
          break
        }
        default: break
      }
    }
  }
}

export function setEchoesFeatures(echoes: IEchoModel[], features: GeoJSON.Feature<GeoJSON.Geometry>[]): void {
  for (const f of features) {
    if (f.properties?.feature && f.properties?.property && f.properties?._id) {
      const featureType = f.properties.feature as string
      const featureProperty = f.properties.property as string
      const featureId = f.properties._id as string | undefined
      switch (featureType) {
        case 'echo':
          if (featureId) {
            const echo = echoes.find(e => e._id === featureId)
            if (echo) {
              setEchoFeatures(echo, [f])
            }
          }
          break
        case 'element':
          if (featureProperty === 'loc.coordinates') {
            let element: IElementModel | undefined
            for (const echo of echoes) {
              for (const el of echo.elements) {
                if (el._id === featureId) {
                  element = el
                }
              }
            }
            if (element && element.loc?.coordinates && f.geometry.type === "Point") {
              element.loc.coordinates = f.geometry.coordinates as [number, number]
            }
          }
          break
        default: break
      }
    }
  }
}

export function setEchoFeatures(echo: IEchoModel, features: GeoJSON.Feature<GeoJSON.Geometry>[]): void {
  for (const feature of features) {
    const featureProperty = feature.properties?.property as string
    if (featureProperty === 'loc.coordinates' && feature.geometry.type === "Point") {
      if (echo.loc?.coordinates) {
        echo.loc.coordinates = feature.geometry.coordinates as [number, number]
      }
    }
    if (featureProperty === 'polygon.coordinates') {
      if (echo.polygon?.coordinates && feature.geometry.type === "MultiLineString") {
        echo.polygon.coordinates = feature.geometry.coordinates as [number, number][][]
      }
    }
  }
}

export function moveElementToNewLocation(element: IElementModel, destination: [number, number], centre?: [number, number]): void {
  if (!element.threed) {
    // only bother moving threed elements
    return
  }
  if (!centre) {
    centre = element.loc?.coordinates
  }
  if (!centre) return

  if (centre && element.loc) {
    const d = distance(centre, destination)
    const b = bearing(centre, destination)
    const transformedCoordinates = moveCoordsToNewLocation(centre, d, b)
    if (transformedCoordinates) {
      element.loc.coordinates = transformedCoordinates
    }
  }
}

export function getSubscriptionForProfile(
  subscriptions: TSubscriptionProfileModel[],
  profile?: TProfileSubscriptionModel
): TSubscriptionType | null | undefined {
  if (profile && profile.subscription_type) {
    return profile.subscription_type
  }
  if (profile?.subscription && profile?.subscription.active && profile?.subscription.type) {
    return profile.subscription.type
  }
  const matchingSub = subscriptions.find(
    (sub) => profile && sub.profile && sub.profile._id === profile._id
  )
  if (matchingSub) {
    return matchingSub.type
  }
  return undefined
}

export function subscriptionOrUserHasCapability(
  capability: ICapabilities,
  user: IUserModel,
  subscriptionType: TSubscriptionType
): boolean {
  if (user.role === 'admin') {
    // if the user is admin, always enable
    return true
  }
  if (user.capabilities.includes(capability)) {
    // if the user has the capability, enable
    return true
  }
  if (SUBSCRIPTION_CAPABILITIES[subscriptionType] &&
    SUBSCRIPTION_CAPABILITIES[subscriptionType][capability]) {
    // if the current subscription type has the capability, enable
    return true
  }
  return false
}

export function getSubscriptionOrUserCapabilities(
  user: IUserModel | undefined,
  subscriptionType: TSubscriptionType
): IUserCapabilities {
  let capabilities: IUserCapabilities = {}
  if (user?.role === 'admin') {
    // if the user is admin, always enable
    capabilities = Object.keys(DEFAULT_CAPABILITIES).reduce((acc, cap) => {
      acc[cap] = true
      return acc
    })
    return capabilities as IUserCapabilities
  }
  if (SUBSCRIPTION_CAPABILITIES[subscriptionType]) {
    // set subscription capabilities
    capabilities = SUBSCRIPTION_CAPABILITIES[subscriptionType]
  }
  user?.capabilities.map(cap => {
    if (!capabilities[cap]) {
      // override with any manual capabilities
      const desc = Object.getOwnPropertyDescriptor(capabilities, cap) || {}
      if (desc.writable) {
        capabilities[cap] = true
      }
    }
  })
  return capabilities as IUserCapabilities
}

export function isDemoWalkTitle(title: string): boolean {
  return /^test|\Wtest|^demo|\Wdemo|^example|\Wexample/i.test(title)
}

export function randomString(length: number, chars: string): string {
  let mask = ''
  if (chars.indexOf('a') > -1) {
    mask += 'abcdefghijklmnopqrstuvwxyz'
  }
  if (chars.indexOf('A') > -1) {
    mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  }
  if (chars.indexOf('#') > -1) {
    mask += '0123456789'
  }
  if (chars.indexOf('!') > -1) {
    mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\'
  }
  let result = ''
  for (let i = length; i > 0; --i) {
    result += mask[Math.round(Math.random() * (mask.length - 1))]
  }
  return result
}

export function convertToSlug(text: string): string {
  return text.toLowerCase().replace(/\W+/gi, '-')
}

export function getExtension(filename: string): string {
  return filename.split('.').pop() || ''
}

export function clamp(num: number, min: number, max: number): number {
  return num <= min ? min : num >= max ? max : num
}

export function validateUUID(value: string): undefined | string {
  const passes = uuidRegex.test(
    value
  )
  return passes
    ? undefined
    : 'Please enter a valid UUID in the form xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
}

export function parseUUID(value: string): string {
  let uuid = ''
  uuid = value.replace(/[^0-9a-fA-F]/i, '')
  uuid = uuid.replace(
    /([0-9a-fA-F]){8}-?([0-9a-fA-F]){,4}-?([0-9a-fA-F]){4}-?([0-9a-fA-F]){4}-?([0-9a-fA-F]){12}/,
    ($1, $2, $3, $4, $5) => `${$1}-${$2}-${$3}-${$4}-${$5}`
  )
  return uuid.toUpperCase()
}

export function parseTrendStats(trendStats?: ITrendStats[], label?: string): IEchoesStats {
  return trendStats && trendStats.length > 0
    ? {
      labels: trendStats[0].labels,
      datasets: [
        {
          backgroundColor: '#395fff',
          label,
          data: trendStats[0].data,
        },
      ],
    }
    : {
      labels: [],
      datasets: [],
    }
}
export function parseMultipleTrendStats(trendStats?: ITrendStats[], labels?: string[]): IEchoesStats {
  return trendStats && labels && trendStats.length > 0
    ? {
      labels: trendStats[0].labels,
      datasets: trendStats.map((collectionStats, i: number) => {
        return {
          backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0'),
          label: `${labels[i]} - Total: ${collectionStats.count}`,
          data: collectionStats.data,
        }
      }),
    }
    : {
      labels: [],
      datasets: [],
    }
}

export const makeDefaultShortName = async (
  centre: [number, number]
): Promise<IPlace | null> => {
  let shortName = ''
  let longName = ''
  const geocodingUri = `${import.meta.env.VITE_MAPBOX_PLACES_API_URL}${centre[0]},${centre[1]}.json?access_token=${import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}&types=${import.meta.env.VITE_MAPBOX_GEOCODING_TYPES}`
  try {
    const results = await axios.get(geocodingUri)

    // prefer places over addresses
    const places = results.data.features.filter(
      (f: { place_type: string | string[] }) => f.place_type.includes('place')
    )
    const localities = results.data.features.filter(
      (f: { place_type: string | string[] }) =>
        f.place_type.includes('locality')
    )

    if (places.length > 0) {
      shortName = places[0].text
      longName = places[0].place_name
    } else if (localities.length > 0) {
      shortName = localities[0].text
      longName = localities[0].place_name
    }
  } catch (err) {
    Sentry.captureException(err)
    console.error(err)
  }

  if (!shortName || !longName) {
    console.info('Could not find place')
    return null
  }

  return {
    lang: 'en',
    short_name: shortName,
    long_name: longName,
  }
}

export function _calculateCollectionCentre(
  echoes: IEchoModel[]
): [number, number] | undefined {
  if (echoes && echoes.length > 0) {
    const features = []
    for (const echo of echoes) {
      if (echo.shape === 'Circle' && echo.loc) {
        const p = point(echo.loc?.coordinates)
        features.push(p)
      } else if (echo.shape === 'Polygon' && echo.polygon) {
        const p = polygon(echo.polygon.coordinates)
        features.push(p)
      }
    }
    const featColl = featureCollection<GeoJSON.Point | GeoJSON.Polygon>(features)
    const centre = center(featColl)
    if (centre.geometry) {
      return centre.geometry.coordinates as [number, number]
    }
  }
  return undefined
}

export function doesEchoMatchText(text: string, echo: IEchoModel): boolean {
  const title = getText(echo.title)
  const re = new RegExp(text, 'i')
  return re.test(title)
}

export function doesCollectionMatchText(text: string, collection: ICollectionModel): boolean {
  const title = getText(collection.title)
  const re = new RegExp(text, 'i')
  return re.test(title)
}

export function formatPrice(prices: IPriceModel[], currency = 'gbp'): string {
  let price = prices.find((p) => p.currency === currency)
  if (!price) {
    price = prices[0]
  }
  if (!price) {
    return ''
  }
  let symbol = '£'
  switch (price.currency) {
    case 'usd':
      symbol = '$'
      break
    default:
      break
  }
  const amount = price.amount || 0.0
  const planAmount = formatMoney(amount / 100, {
    symbol,
  })
  return planAmount
}

export function getMetadatum(obj: IMetadatum[], rel: string): string {
  if (obj && obj.length > 0) {
    const o = obj.find(o => o.rel === rel)
    return o ? o.text : ''
  } return ''
}

function stripIdsFromObject(obj: Record<string, unknown>): Record<string, unknown> {
  if (obj._id) {
    unset(obj, '_id')
  }
  for (const [, value] of Object.entries(obj)) {
    if (isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        const element = value[i]
        value[i] = stripIdsFromObject(element)
      }
    } else if (isObject(value)) {
      unset(value, '_id')
    }
  }
  return obj
}

export function removeIdsFromCollection(collection: ICollectionModel): ICollectionModel | null {
  try {
    const typecastColl = collection as unknown
    const strippedColl = stripIdsFromObject(typecastColl as Record<string, unknown>)
    const typecastStrippedColl = strippedColl as unknown
    return typecastStrippedColl as ICollectionModel
  } catch (err) {
    Sentry.captureException(err)
    console.error('Error removing ids from collection')
    console.error(err)
    console.error(collection)
    return null
  }
}

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
